import React from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { SizeBranch } from "../../lib/size-branch"

export const FashionGallerySlider = ({ rtl }) => {
  const [viewportRef] = useEmblaCarousel({
    loop: true,
    dragFree: true,
    containScroll: "trimSnaps",
    direction: rtl ? "rtl" : "ltr",
  })

  return (
    <Slider>
      <Viewport ref={viewportRef}>
        <Container>
          <Slide>
            <SizeBranch>
              <StaticImage
                quality="15"
                alt=""
                src="../../images/fashion-gallery/slide-1.jpg"
              />
              <StaticImage
                quality="80"
                alt=""
                src="../../images/fashion-gallery/slide-1.jpg"
              />
            </SizeBranch>
          </Slide>
          <Slide>
            <SizeBranch>
              <StaticImage
                quality="15"
                alt=""
                src="../../images/fashion-gallery/slide-2.jpg"
              />
              <StaticImage
                quality="80"
                alt=""
                src="../../images/fashion-gallery/slide-2.jpg"
              />
            </SizeBranch>
          </Slide>
          <Slide>
            <SizeBranch>
              <StaticImage
                quality="15"
                alt=""
                src="../../images/fashion-gallery/slide-3.jpg"
              />
              <StaticImage
                quality="80"
                alt=""
                src="../../images/fashion-gallery/slide-3.jpg"
              />
            </SizeBranch>
          </Slide>
          <Slide>
            <SizeBranch>
              <StaticImage
                quality="15"
                alt=""
                src="../../images/fashion-gallery/slide-4.jpg"
              />
              <StaticImage
                quality="80"
                alt=""
                src="../../images/fashion-gallery/slide-4.jpg"
              />
            </SizeBranch>
          </Slide>
        </Container>
      </Viewport>
    </Slider>
  )
}

const Slider = styled.div`
  position: relative;
  width: 100%;
`

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const Slide = styled.div`
  position: relative;
  min-width: 27vw;

  img {
    width: 100%;
    height: 100%;
  }
`
