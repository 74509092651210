import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { SizeBranch } from "../../lib/size-branch"

import { Title, screenSize } from "../../index"

export const Description = ({ contentTop, contentBottom }) => {
  return (
    <DescriptionContainer>
      <ContentContainer>
        <Content>
          <Title data-size="s">{contentTop}</Title>
          <Title data-size="s">{contentBottom}</Title>
        </Content>
      </ContentContainer>
      <ImageContainer>
        <StaticImage src="../../images/description.jpg" quality="99" />
      </ImageContainer>
      <Cell />
      <Cell />
    </DescriptionContainer>
  )
}

const DescriptionContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 25.1vw 9vw;
  grid-template-rows: 1fr 11vw;
  padding-top: 6vw;
  background-color: var(--teal200);

  @media screen and (max-width: ${screenSize.xl}) {
    padding-top: 7vw;
    grid-template-columns: 1fr 29.14vw 7vw;
    grid-template-rows: 1fr 7vw;
  }

  @media screen and (max-width: ${screenSize.l}) {
    padding-top: 7vw;
    grid-template-columns: 1fr 29vw 7vw;
    grid-template-rows: 1fr 7vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    padding-top: 0;
    grid-template-columns: 1fr 336px;
    grid-template-rows: 1fr 188px 316px;
  }
`

const ContentContainer = styled.div`
  color: var(--white);
  padding: 0 18.7vw 0 9.48vw;

  @media screen and (max-width: ${screenSize.xl}) {
    padding: 1vw 11.25vw 0 4.68vw;
  }

  @media screen and (max-width: ${screenSize.l}) {
    padding: 3vw 11vw 0 4.5vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    padding: 10vw 7.5vw 9vw 7.5vw;
    grid-column: 1 / 4;
    grid-row: 1 / 1;
  }
`

const Content = styled.div`
  & > div {
    &:last-child {
      margin-top: 2.5vw;

      @media screen and (max-width: ${screenSize.m}) {
        margin-top: 4vw;
      }
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  grid-row: 1 / 3;
  grid-column: 2 / 3;

  @media screen and (max-width: ${screenSize.m}) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  & > div {
    margin-bottom: -4px;
  }
`

const Cell = styled.div`
  background: #fff;
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  @media screen and (max-width: ${screenSize.m}) {
    grid-row: 3 / 4;
  }

  &:last-child {
    grid-column: 3 / 3;
  }
`
