import * as React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { SizeBranch } from "../../lib/size-branch"

import { screenSize, Title } from "../../components"

import { SliderArrow } from "../../images"

const SliderSetup = ({ children, ...rest }) => {
  return (
    <Slider
      infinite
      slidesToShow={3}
      dir="rtl"
      nextArrow={<Left />}
      prevArrow={<Right />}
      responsive={[
        {
          breakpoint: 1200,
          settings: {
            arrows: false,
          },
        },
      ]}
      {...rest}
    >
      {children}
    </Slider>
  )
}

const IS_SAFARI =
  typeof window !== "undefined" &&
  navigator.userAgent.toLowerCase().indexOf("safari") !== -1 &&
  navigator.userAgent.toLowerCase().indexOf("chrome") <= -1

const useForcedRerenderHackSafari = () => {
  // it is needed, because for some reason StaticImage with slider is weirdly broken in Safari, but only the first one,
  // and only until rerendered by any reason
  // so here we force rerender of this component in safari, once client is hydrated
  if (!IS_SAFARI) return

  // it is okay, because hooks order will not change during application's life - it is just linter doesn't know that
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [rerendered, forceRender] = React.useReducer(s => !s, false)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (!rerendered) {
      forceRender()
    }
  }, [rerendered])
}

// We need all that to use Gatsby's StaticImage automatic optimizations
const GroupOne = () => {
  useForcedRerenderHackSafari()

  return (
    <SliderSetup>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={250}
            key="1-1"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_1/Pic_1_1.jpg"
            loading="lazy"
          />
          <StaticImage
            width={1000}
            key="1-1"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_1/Pic_1_1.jpg"
            loading="lazy"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={250}
            key="1-2"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_1/Pic_1_2.jpg"
            loading="lazy"
          />
          <StaticImage
            width={1000}
            key="1-2"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_1/Pic_1_2.jpg"
            loading="lazy"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={250}
            key="1-3"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_1/Pic_1_3.jpg"
            loading="lazy"
          />
          <StaticImage
            width={1000}
            key="1-3"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_1/Pic_1_3.jpg"
            loading="lazy"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={250}
            key="1-4"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_1/Pic_1_4.jpg"
            loading="lazy"
          />
          <StaticImage
            width={1000}
            key="1-4"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_1/Pic_1_4.jpg"
            loading="lazy"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={250}
            key="1-5"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_1/Pic_1_5.jpg"
            loading="lazy"
          />
          <StaticImage
            width={1000}
            key="1-5"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_1/Pic_1_5.jpg"
            loading="lazy"
          />
        </SizeBranch>
      </Slide>
    </SliderSetup>
  )
}

const GroupTwo = () => {
  return (
    <SliderSetup>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="2-1"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_2/Pic_2_1.jpg"
            loading="lazy"
          />
          <StaticImage
            width={1000}
            key="2-1"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_2/Pic_2_1.jpg"
            loading="lazy"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="2-2"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_2/Pic_2_2.jpg"
          />
          <StaticImage
            width={1000}
            key="2-2"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_2/Pic_2_2.jpg"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="2-3"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_2/Pic_2_3.jpg"
          />
          <StaticImage
            width={1000}
            key="2-3"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_2/Pic_2_3.jpg"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="2-4"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_2/Pic_2_4.jpg"
          />
          <StaticImage
            width={1000}
            key="2-4"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_2/Pic_2_4.jpg"
          />
        </SizeBranch>
      </Slide>

      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="2-5"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_2/Pic_2_5.jpg"
          />
          <StaticImage
            width={1000}
            key="2-5"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_2/Pic_2_5.jpg"
          />
        </SizeBranch>
      </Slide>
    </SliderSetup>
  )
}

const GroupThree = () => {
  return (
    <SliderSetup>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="3-1"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_3/Pic_3_1.jpg"
          />
          <StaticImage
            width={1000}
            key="3-1"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_3/Pic_3_1.jpg"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="3-2"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_3/Pic_3_2.jpg"
          />
          <StaticImage
            width={1000}
            key="3-2"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_3/Pic_3_2.jpg"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="3-3"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_3/Pic_3_3.jpg"
          />
          <StaticImage
            width={1000}
            key="3-3"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_3/Pic_3_3.jpg"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="3-4"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_3/Pic_3_4.jpg"
          />
          <StaticImage
            width={1000}
            key="3-4"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_3/Pic_3_4.jpg"
          />
        </SizeBranch>
      </Slide>
      <Slide>
        <SizeBranch>
          <StaticImage
            width={300}
            key="3-5"
            alt=""
            quality="50"
            src="../../images/catwalk/catwalk_3/Pic_3_5.jpg"
          />
          <StaticImage
            width={1000}
            key="3-5"
            alt=""
            quality="80"
            src="../../images/catwalk/catwalk_3/Pic_3_5.jpg"
          />
        </SizeBranch>
      </Slide>
    </SliderSetup>
  )
}

const slides = {
  GroupOne,
  GroupTwo,
  GroupThree,
}

const locale = {
  GroupOne: {
    en: "A/W 22",
    ar: "تشكيلة جديدة لخريف وشتاء 2022",
  },
  GroupTwo: {
    en: "Winter Separates",
    ar: "قطع الشتاء",
  },
  GroupThree: {
    en: "Dresses",
    ar: "فساتين",
  },
}

const Left = ({ onClick }) => (
  <StyledArrow data-next onClick={onClick}>
    <SliderArrow />
  </StyledArrow>
)

const Right = ({ onClick }) => (
  <StyledArrow data-next={false} onClick={onClick}>
    <SliderArrow />
  </StyledArrow>
)

const TitleBlock = ({ groupKey, rtl, onClick, current }) => (
  <Title
    data-size="l"
    onClick={() => onClick(groupKey)}
    data-active={current === groupKey}
  >
    {rtl ? locale[groupKey]?.ar ?? "" : locale[groupKey]?.en ?? ""}
  </Title>
)

const groups = Object.keys(slides)

export const CatwalkGallerySlider = ({ rtl }) => {
  const [slidesGroup, setSlides] = React.useState("GroupOne")
  const Slides = slides[slidesGroup]

  return (
    <div>
      <SlidesLinks>
        {groups.map(groupKey => {
          return (
            <TitleBlock
              groupKey={groupKey}
              onClick={setSlides}
              rtl={rtl}
              current={slidesGroup}
            />
          )
        })}
      </SlidesLinks>
      <Slides />
    </div>
  )
}

const Slide = styled.div`
  outline: none;
  display: inline-block;

  & img,
  & picture {
    width: 100%;
    height: 100%;
    display: block;
  }

  & .large {
    display: none;
    @media (min-width: ${screenSize.tab}) {
      display: block;
    }
  }

  & .small {
    display: block;
    @media (min-width: ${screenSize.tab}) {
      display: none;
    }
  }
`

const SlidesLinks = styled.div`
  display: flex;
  margin: 6.4vw 0 1.35vw;
  padding-left: 9.48vw;
  & > div {
    margin-right: 5.625vw;
    cursor: pointer;
    opacity: 0.3;
    &[data-active="true"] {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${screenSize.xl}) {
    padding-left: 4.68vw;
  }
  @media screen and (max-width: ${screenSize.m}) {
    padding-top: 10vw;
    padding-left: 6vw;
  }
`

const StyledArrow = styled.div`
  width: 4.5vw;
  height: 4.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  /*! @noflip */
  left: 3.75vw;
  top: 50%;
  margin-top: -2.25vw;
  cursor: pointer;
  svg {
    max-width: 50%;
    max-height: 50%;
  }
  &[data-next="true"] {
    /*! @noflip */
    left: auto;
    /*! @noflip */
    right: 3.75vw;
    transform: rotate(180deg);
  }
`
