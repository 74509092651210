import { css } from "styled-components"

export const CSSVariables = css`
  --black: #000;
  --white: #fff;
  --teal100: #cee8e4;
  --teal200: #a8d0ca;
  --teal300: #8abfb7;
  --gray100: #b2bdbb;
  --gray200: #646360;
  --orange: #d56454;
  --orange200: #86302f;
`

export const screenSize = {
  xs: "320px",
  s: "359px",
  ms: "400px",
  m: "639px",
  tab: "800px",
  l: "1279px",
  xl: "1439px",
  xxl: "1919px",
}
