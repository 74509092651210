import React from "react"
import styled from "styled-components"

import { Close, Menu } from "../images"
import { screenSize } from "./variables"
import { P } from "./typography"
import { MENU_PAGES } from "./menu"

export const MenuMobile = ({ rtl }) => {
  const [isOpen, toggleOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "scroll"
    }
  }, [isOpen])

  if (!isOpen)
    return (
      <MenuOpen>
        <Menu onClick={() => toggleOpen(true)} />
      </MenuOpen>
    )

  return (
    <Wrapper>
      <CloseBtn>
        <Close onClick={() => toggleOpen(false)} />
      </CloseBtn>
      <StyledMenu>
        {MENU_PAGES.map(page => (
          <a href={page.link} key={page.id} onClick={() => toggleOpen(false)}>
            {rtl ? page.ar : page.en}
          </a>
        ))}
        <Language rtl={rtl}>
          <a href={rtl ? "/en/" : "/ar/"}>
            {rtl ? "English version" : "النسخة العربية"}
          </a>
        </Language>
      </StyledMenu>
      <Footer>
        <P>customercare@leem.com, +966 800 440 0099</P>
        <P>An Nada Riyadh, Saudi Arabia</P>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background: var(--white);
  padding: 13vw 6.5vw;
  display: flex;
  flex-direction: column;
`

const CloseBtn = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledMenu = styled.div`
  a {
    text-decoration: none;
    font-size: max(18px, 2.8vw);
    line-height: 2.66;
    display: block;
    color: var(--black);
    text-transform: uppercase;
  }
`

const Language = styled.div`
  margin-top: 10px;

  a {
    text-transform: none;
  }
`

const MenuOpen = styled.div`
  @media screen and (min-width: 640px) {
    display: none;
  }

  @media screen and (max-width: ${screenSize.s}) {
    margin-top: 6vw;
  }
`

const Footer = styled.footer`
  margin-top: auto;
  margin-bottom: 10vw;
`
