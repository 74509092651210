import * as React from 'react';
import styled from 'styled-components';

import { H2, P, screenSize } from '../../components';

import { CatwalkGallerySlider } from './slider';

export const CatwalkGallery = ({ description, children, rtl }) => (
  <>
    <StyledFashionGallery>
      <FashionGalleryContent>
        <H2>{children}</H2>
        <P>{description}</P>
      </FashionGalleryContent>
      <CatwalkGallerySlider rtl={rtl} />
    </StyledFashionGallery>
  </>
);

const StyledFashionGallery = styled.div`
  margin-bottom: 5vw;
`;

const FashionGalleryContent = styled.div`
  padding-left: 9.48vw;

  @media screen and (max-width: ${screenSize.xl}) {
    padding-left: 4.68vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    padding-top: 5vw;
    padding-left: 6vw;
  }
`;
