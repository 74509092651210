import React from 'react';
import styled from 'styled-components';

import { screenSize, SubTitle } from '../../components';
import { BrandsSlider } from './slider';

const CONTENT = {
  mojeh:
    'Known for timeless conservative wear for today’s fashion-conscious woman, Saudi Arabian brand Leem has always been a purveyor of bold expression, chic in its discretion. – MOJEH, April 2019',
  bazaar:
    'Leem’s Ramadan collection is inspired by the bond between mother and daughter and the bond between sisters. – Harper’s Bazaar, April 2019',
  vogue:
    'Leem is a salute to modest femininity intended to equip women with the tools to build a fashion-conscious yet modest wardrobe. – Vogue, June 2019',
  grazia:
    'Distinguished by its acute eye for tailoring while offering affordable solutions for the fashion-forward woman, Middle Eastern label, Leem lays the foundation for a sturdy travel wardrobe. – GRAZIA, October 2019',
  woman:
    'Saudi Arabian fashion brand Leem merged their modest aesthetic with some of the chicest elements of the decade to create a statement-making collection that will make anyone feel powerful and youthful. – Emirates Woman, March 2020',
  lofficiel:
    'The season, Leem is focusing on powerful looks in eye-popping shapes, including maxi volumes combined with softly draped fabrics for a flattering silhouette, puffed sleeves, frills, metallics, lacework, and tie-dye. – L’Officiel, March 2020',
};

export const Brands = () => {
  const [text, changeText] = React.useState(CONTENT.grazia);

  return (
    <Container>
      <BrandsSlider changeText={changeText} text={text} content={CONTENT} />
      <Text>
        <SubTitle data-size="l">{text}</SubTitle>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 73vw;

  @media screen and (max-width: ${screenSize.l}) {
    max-width: 85vw;
  }

  @media screen and (max-width: ${screenSize.tab}) {
    max-width: 100%;
  }
`;

const Text = styled.div`
  text-align: center;
  max-width: 85%;
  margin: max(50px, min(7.5vw, 75px)) auto 0;
  min-height: max(86px, min(13.4vw, 126px));

  & > div {
    font-weight: 400 !important;
  }
`;
