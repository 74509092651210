import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { H2, P, screenSize } from "../../index"

import { FashionGallerySlider } from "./slider"

export const FashionGallery = ({ children, description, rtl }) => (
  <>
    <StyledFashionGallery>
      <FashionGalleryContent>
        <H2>{children}</H2>
        <P>{description}</P>
      </FashionGalleryContent>
      <FashionGallerySlider />
    </StyledFashionGallery>
    <FashionGalleryBanner>
      <StaticImage
        layout="fullWidth"
        src="../../images/secondary-banner-xl.jpg"
        quality="85"
        alt=""
      />
    </FashionGalleryBanner>
  </>
)

const StyledFashionGallery = styled.div`
  padding-left: 9.48vw;

  @media screen and (max-width: ${screenSize.xl}) {
    padding-top: 2.35vw;
    padding-left: 4.68vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    padding-top: 10vw;
    padding-left: 6vw;
  }
`

const FashionGalleryContent = styled.div`
  margin-bottom: 4.8vw;
`

const FashionGalleryBanner = styled.div`
  margin: 10.5vw 0 9.5vw;
  width: 100%;

  @media screen and (max-width: ${screenSize.xl}) {
    margin: 9.3vw 0;
  }
`
