import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import {
  Bazaar,
  GraziaLogo,
  Lofficiel,
  MojehLogo,
  VogueLogo,
  WomanLogo,
} from '../../images';

import { screenSize } from '../../components';

const SliderMobile = ({ changeText, content, text }) => (
  <SliderMobileWrapper>
    <Slider
      infinite
      slidesToShow={3}
      arrows={false}
      variableWidth
      swipeToSlide
      centerMode
    >
      <Image>
        <GraziaLogo
          onClick={() => changeText(content.grazia)}
          data-active={text === content.grazia}
        />
      </Image>
      <Image>
        <Bazaar
          onClick={() => changeText(content.bazaar)}
          data-active={text === content.bazaar}
        />
      </Image>
      <Image>
        <MojehLogo
          onClick={() => changeText(content.mojeh)}
          data-active={text === content.mojeh}
        />
      </Image>
      <Image>
        <VogueLogo
          onClick={() => changeText(content.vogue)}
          data-active={text === content.vogue}
        />
      </Image>

      <Image>
        <WomanLogo
          onClick={() => changeText(content.woman)}
          data-active={text === content.woman}
        />
      </Image>
      <Image>
        <Lofficiel
          onClick={() => changeText(content.lofficiel)}
          data-active={text === content.lofficiel}
        />
      </Image>
    </Slider>
  </SliderMobileWrapper>
);

export const BrandsSlider = ({ changeText, content, text }) => {
  return (
    <>
      <SliderMobile {...{ changeText, content, text }} />
      <SliderDesktopWrapper>
        <Images>
          <Image>
            <Bazaar
              onClick={() => changeText(content.bazaar)}
              data-active={text === content.bazaar}
            />
          </Image>
          <Image>
            <MojehLogo
              onClick={() => changeText(content.mojeh)}
              data-active={text === content.mojeh}
            />
          </Image>
          <Image>
            <VogueLogo
              onClick={() => changeText(content.vogue)}
              data-active={text === content.vogue}
            />
          </Image>
          <Image>
            <GraziaLogo
              onClick={() => changeText(content.grazia)}
              data-active={text === content.grazia}
            />
          </Image>
          <Image>
            <WomanLogo
              onClick={() => changeText(content.woman)}
              data-active={text === content.woman}
            />
          </Image>
          <Image>
            <Lofficiel
              onClick={() => changeText(content.lofficiel)}
              data-active={text === content.lofficiel}
            />
          </Image>
        </Images>
      </SliderDesktopWrapper>
    </>
  );
};

const Images = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 40px;
    opacity: 0.4;
    cursor: pointer;

    &[data-active='true'] {
      opacity: 1;
    }

    @media screen and (max-width: ${screenSize.l}) {
      height: 30px;
    }

    @media screen and (max-width: ${screenSize.m}) {
      height: 20px;
    }
  }
`;

const Image = styled.div`
  svg {
    height: 3vw;
    opacity: 0.4;
    cursor: pointer;

    &[data-active='true'] {
      opacity: 1;
    }

    @media screen and (max-width: ${screenSize.xl}) {
      height: 40px;
    }

    @media screen and (max-width: ${screenSize.l}) {
      height: 30px;
    }

    @media screen and (max-width: ${screenSize.m}) {
      height: 20px;
    }
  }
`;

const SliderDesktopWrapper = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const SliderMobileWrapper = styled.div`
  @media screen and (min-width: 901px) {
    display: none;
  }

  & .slick-slide {
    padding: 0 24px;
  }
`;
