import React from "react"
import styled from "styled-components"

import { screenSize } from "./variables"

export const Menu = ({ rtl }) => (
  <StyledMenu>
    <div>
      {MENU_PAGES.map(page => (
        <StyledMenuLink href={page.link} key={page.id} rtl={rtl}>
          {rtl ? page.ar : page.en}
        </StyledMenuLink>
      ))}
    </div>
    <Language rtl={rtl}>
      <a href={rtl ? "/en/" : "/ar/"}>
        {rtl ? "English version" : "النسخة العربية"}
      </a>
    </Language>
  </StyledMenu>
)

const StyledMenu = styled.div`
  padding-top: 1vw;
  flex: 1;
  display: flex;

  @media screen and (max-width: ${screenSize.m}) {
    display: none;
  }
`

const StyledMenuLink = styled.a`
  text-decoration: none;
  font-size: 1.17vw;
  line-height: 1.4;
  display: inline-block;
  color: var(--white);
  text-transform: uppercase;
  margin-left: ${({ rtl }) => (rtl ? "40px" : "48px")};
  font-weight: 500;

  &:hover {
    color: var(--black);
  }
`

const Language = styled.div`
  margin-left: auto;

  a {
    color: var(--white);
    text-transform: none;

    &:hover {
      color: var(--teal200);
      text-transform: none;
      ${({ rtl }) => (rtl ? "" : "text-shadow: 1px 1px var(--white)")};
    }
  }
`

export const MENU_PAGES = [
  {
    id: 0,
    en: "About Us",
    ar: "نبذة عنا",
    link: "#about",
  },
  {
    id: 1,
    en: "Collection",
    ar: "التشكيلة",
    link: "#collection",
  },
  {
    id: 2,
    en: "Press",
    ar: "أخبار صحفية",
    link: "#press",
  },
  {
    id: 3,
    en: "App",
    ar: "التطبيق",
    link: "#app",
  },
  {
    id: 4,
    en: "Stores",
    ar: "المتاجر",
    link: "#stores",
  },
]
