import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { H2, P, screenSize } from "../../index"
import { AppStore, GPlay } from "../../images"

export const Explore = ({ header, description }) => (
  <Grid>
    <Content>
      <H2>{header}</H2>
      <P>{description}</P>
      <Social>
        <a
          href="https://apps.apple.com/sa/app/leem/id1501163845"
          target="_blank"
        >
          <AppStore />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.leem"
          target="_blank"
        >
          <GPlay />
        </a>
      </Social>
    </Content>
    <Banner>
      <StaticImage
        src="../../images/smartphone.png"
        format="webp"
        quality="70"
      />
    </Banner>
  </Grid>
)

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50vw);
  align-items: center;
  padding-top: 12.5vw;
  @media screen and (max-width: ${screenSize.m}) {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    align-items: start;
  }
  & > div {
    padding-left: 7vw;
    padding-right: 7vw;
    @media screen and (max-width: ${screenSize.m}) {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
      @media screen and (max-width: ${screenSize.m}) {
        grid-row: 1 / 1;
      }
    }
  }
`

const Content = styled.div`
  @media screen and (max-width: ${screenSize.m}) {
    padding: 10vw 0;
  }
  @media screen and (max-width: ${screenSize.m}) {
    p {
      padding-right: 17vw;
    }
  }
  img {
    margin-right: 1vw;
  }
`

const Social = styled.div`
  margin-top: 5vw;
`

const Banner = styled.div`
  img {
    width: 100%;
  }
  @media screen and (max-width: ${screenSize.xl}) {
    img {
      width: 44vw;
      height: 50vw;
    }
  }
  @media screen and (max-width: ${screenSize.l}) {
    img {
      width: 47vw;
      height: 54vw;
    }
  }
  @media screen and (max-width: ${screenSize.m}) {
    img {
      width: auto;
      max-width: 100%;
      display: block;
      height: auto;
      margin: 0 auto;
    }
  }
`
