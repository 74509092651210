import React from 'react';
import styled from 'styled-components';

import { H4, screenSize } from '../../index';

export const Contacts = ({ header, children, rtl }) => (
  <Container data-rtl={rtl}>
    <H4>{header}</H4>
    {children}
  </Container>
);

const Container = styled.div`
  border-top: 1px solid var(--teal200);
  padding: 2vw 7vw;

  &[data-rtl='true'] {
    direction: ltr;
  }

  @media screen and (max-width: ${screenSize.m}) {
    padding-top: 10vw;
  }

  h4 {
    margin-bottom: 1.5vw;

    @media screen and (max-width: ${screenSize.m}) {
      margin-bottom: 7vw;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: ${screenSize.m}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 6.5vw;

    & > div:last-child {
      margin-top: -18vw;
    }
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4vw;
  padding-bottom: 4vw;

  svg {
    width: 10vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    svg {
      display: none;
    }
  }

  p {
    margin-top: 0.5em;
  }
`;
