import React from "react"
import styled from "styled-components"

import { screenSize } from "./variables"

const pattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4]\d\.|1\d{2}\.|\d{1,2}\.))((25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\.){2}(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\]?$)/
)

export const Input = ({ rtl }) => {
  const [email, setEmail] = React.useState("")
  const [isValid, validate] = React.useState(false)
  const [isSent, setSent] = React.useState(false)

  React.useEffect(() => {
    if (pattern.test(email)) {
      validate(true)
    } else {
      validate(false)
    }
  }, [email])

  const onEmailSend = React.useCallback(userEmail => {
    fetch("/server/info.php", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email: userEmail }),
    }).then(() => {
      setSent(true)
      setEmail("")
    })
  }, [])

  return (
    <>
      <Container>
        <StyledInput
          type="text"
          placeholder={rtl ? "البريد اللكتروني" : "Your email address"}
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <Button
          data-active={isValid && !isSent}
          onClick={isValid ? () => onEmailSend(email) : null}
        >
          {rtl ? "الاشتراك​" : "Subscribe"}
        </Button>
      </Container>
      {isSent && (
        <SuccessMessage>
          {rtl ? "شكرا لك على الاشتراك!" : "Thank you for subscribing!"}
        </SuccessMessage>
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  font-size: 1vw;

  @media screen and (max-width: ${screenSize.xl}) {
    font-size: 1rem;
  }
`

const SuccessMessage = styled.div`
  margin-top: 10px;
`

const StyledInput = styled.input`
  border: 1px solid var(--teal200);
  width: 100%;
  padding: 0 15px;
  outline: none;

  &:focus {
    border-color: var(--teal300);
  }
`

const Button = styled.div`
  background: var(--teal200);
  padding: 15px 36px;
  color: var(--white);
  display: inline-block;

  &[data-active="true"] {
    cursor: pointer;
    background: var(--teal300);
  }
`
