import styled from "styled-components"
import { screenSize } from "../components"

export const SizeBranch = styled.div`
  & > *:first-child {
    @media (min-width: ${screenSize.m}) {
      display: none;
    }
  }

  & > *:last-child {
    display: none;
    @media (min-width: ${screenSize.m}) {
      display: block;
    }
  }
`
