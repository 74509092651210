import { createGlobalStyle } from 'styled-components';
import { CSSVariables, screenSize } from './components';

export const GlobalStyles = createGlobalStyle`
  :root {
    ${CSSVariables}
  }

  * {
    box-sizing: border-box;
  }

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  body {
    margin: 0;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400;
    min-width: ${screenSize.s};
  }

  main {
    display: block;
  }

  p {
     line-height: 1.6;
     margin-bottom: 0;
  }

  h1 {
    margin: 0;
    font-weight: 400;
  }

  h2, h3, h4 {
    margin: 0;
    font-weight: 300;
  }

  a {
    background-color: transparent;
  }

  b,
  strong {
    font-weight: bolder;
  }

  img {
    border-style: none;
  }

  [hidden] {
    display: none;
  }

  .highlighted {
    background-color: var(--highlight);
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  /**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */

  button,
  input { /* 1 */
    overflow: visible;
  }

  /**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */

  button,
  select { /* 1 */
    text-transform: none;
  }

  /**
   * Correct the inability to style clickable types in iOS and Safari.
   */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  /**
   * Remove the inner border and padding in Firefox.
   */

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
   * Restore the focus styles unset by the previous rule.
   */

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  .slick-list {
    padding: 0!important;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
`;
