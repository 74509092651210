import React from "react"
import styled from "styled-components"

import { H1, Menu, MenuMobile, screenSize } from "../../index"

import { LogoWhite } from "../../images"

import HeaderBgXl from "../../images/main-banner-xl.jpg"
import HeaderBgM from "../../images/main-banner-m.jpg"
import HeaderBgS from "../../images/main-banner-s.jpg"

const initScroller = () => {
  if (typeof window === "undefined") return { scrollToNext: () => {} }

  let index = 0
  const anchors = [...document.querySelectorAll("a[name]")]

  const scrollToNext = () => {
    if (index < anchors.length) {
      anchors[index].scrollIntoView({ behavior: "smooth", block: "start" })
      index += 1
    } else {
      index = 0
      scrollToNext()
    }
  }

  return { scrollToNext }
}

const useScroller = () => React.useState(initScroller)[0]

const ScrollTrigger = ({ rtl }) => {
  const { scrollToNext } = useScroller()

  return (
    <Scroll onClick={scrollToNext} rtl={rtl}>
      {rtl ? "حرك للأسفل" : "Scroll down"}
    </Scroll>
  )
}

export const Header = ({ children, rtl }) => {
  return (
    <HeaderLayout>
      <BgImageContainer rtl={rtl}>
        <picture>
          <source srcSet={HeaderBgXl} media={`(min-width: ${screenSize.m})`} />
          <source srcSet={HeaderBgM} media={`(min-width: ${screenSize.ms})`} />
          <img src={HeaderBgS} alt="" />
        </picture>
      </BgImageContainer>
      <ContentContainer>
        <Nav>
          <LogoWhite />
          <Menu rtl={rtl} />
          <MenuMobile rtl={rtl} />
        </Nav>
        <Slogan rtl={rtl}>
          <H1>{children}</H1>
        </Slogan>
        <ScrollTrigger rtl={rtl} />
      </ContentContainer>
    </HeaderLayout>
  )
}

const HeaderLayout = styled.div`
  min-height: 55vw;
  position: relative;

  @media screen and (max-width: ${screenSize.s}) {
    min-height: 170vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    min-height: 640px;
  }

  @media screen and (min-width: ${screenSize.m}) {
    max-width: 100vw;
    min-height: 70vw;
  }

  @media screen and (min-width: ${screenSize.xxl}) {
    min-height: auto;
    height: 70vw;
  }
`

const BgImageContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  ${({ rtl }) => (rtl ? "transform: scale(-1, 1);" : "")}

  & > * {
    & img {
      object-fit: cover;
    }

    @media screen and (min-width: ${screenSize.ms}) and (max-width: ${screenSize.m}) {
      margin-left: -40vw;
    }

    @media screen and (min-width: ${screenSize.m}) {
      min-height: 100%;

      & img {
        height: 70vw;
      }
    }
  }
`

const ContentContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;

  padding: 2vw 3.5vw 2vw 9.5vw;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${screenSize.s}) {
    padding: 7.5vw;

    svg {
      max-width: 16vw;
    }
  }

  @media screen and (max-width: ${screenSize.m}) {
    padding: 13vw 6.5vw 16vw 9.5vw;

    svg {
      max-width: 16vw;
      max-height: 12vw;
    }
  }

  @media screen and (max-width: ${screenSize.xl}) {
    svg {
      max-width: 76px;
      max-height: 60px;
    }
  }

  @media screen and (min-width: ${screenSize.xxl}) {
    svg {
      max-width: 116px;
      max-height: 91px;
    }
  }
`

const Nav = styled.div`
  display: flex;
  margin-bottom: 9.5vw;

  @media screen and (max-width: ${screenSize.m}) {
    justify-content: space-between;
  }
`

const Slogan = styled.div`
  color: var(--white);
  text-align: ${({ rtl }) => (rtl ? "left" : "left")};
  margin-left: ${({ rtl }) => (rtl ? "auto" : "0")};
  margin-right: ${({ rtl }) => (rtl ? "0" : "auto")};

  @media screen and (max-width: ${screenSize.m}) {
    margin-right: 0;
    margin-top: auto;
  }
`

const Scroll = styled.button`
  background: none;
  border: none;
  position: fixed;
  right: 3.5vw;
  bottom: 1.66vw;
  color: white;
  mix-blend-mode: difference;

  @media screen and (max-width: ${screenSize.l}) {
    display: none;
  }
`
