import styled from "styled-components"

import { screenSize } from "./variables"

export const H1 = styled.h1`
  font-size: max(6.927vw, 120px);
  line-height: 1;
  font-variant-ligatures: none;

  html:not(.wf-brandongrotesque-n4-active) & {
    font-size: max(6.5vw, 112px);
    line-height: 1.1;
  }

  @media screen and (max-width: ${screenSize.xl}) {
    font-size: max(8.75vw, 104px);
    line-height: 1.1;

    html:not(.wf-brandongrotesque-n4-active) & {
      font-size: max(6.5vw, 96px);
      line-height: 1;
    }
  }

  @media screen and (max-width: ${screenSize.l}) {
    font-size: min(14vw, 80px);

    html:not(.wf-brandongrotesque-n4-active) & {
      font-size: max(6.5vw, 72px);
      line-height: 1;
    }
  }

  @media screen and (max-width: ${screenSize.m}) {
    font-size: min(20vw, 64px);

    html:not(.wf-brandongrotesque-n4-active) & {
      font-size: max(6.5vw, 56px);
      line-height: 1;
    }
  }
`

export const H2 = styled.h2`
  font-size: 4.375vw;
  line-height: 1.1;

  @media screen and (max-width: ${screenSize.xl}) {
    font-size: 5.6vw;
  }

  @media screen and (max-width: ${screenSize.l}) {
    font-size: 6.5vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    font-size: 8.3vw;
  }
`

export const H3 = styled.h3`
  font-size: 2.5vw;
  line-height: 1.5;

  @media screen and (max-width: ${screenSize.xl}) {
    font-size: 3.75vw;
    line-height: 1.5;
  }

  @media screen and (max-width: ${screenSize.l}) {
    font-size: 5.6vw;
    line-height: 1.16;
  }

  @media screen and (max-width: ${screenSize.m}) {
    font-size: 8.33vw;
  }
`

export const H4 = styled.h4`
  font-size: 36px;
  line-height: 2;

  @media screen and (max-width: ${screenSize.l}) {
    font-size: 2.8vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    font-size: 5.5vw;
  }

  @media screen and (max-width: ${screenSize.s}) {
    font-size: 8vw;
    line-height: 1.2;
  }
`

export const Title = styled.div`
  &[data-size="l"] {
    font-size: 36px;
    line-height: 2;

    @media screen and (max-width: ${screenSize.l}) {
      font-size: 30px;
      line-height: 1.4;
    }

    @media screen and (max-width: ${screenSize.m}) {
      font-size: 18px;
      line-height: 1.5;
    }

    @media screen and (max-width: ${screenSize.s}) {
      font-size: 15px;
      line-height: 1.4;
    }
  }

  &[data-size="s"] {
    font-size: 1.7vw;
    line-height: 1.27;

    @media screen and (max-width: ${screenSize.xl}) {
      font-size: 1.875vw;
      line-height: 1.5;
    }

    @media screen and (max-width: ${screenSize.l}) {
      font-size: 1.8vw;
      line-height: 1.5;
    }

    @media screen and (max-width: ${screenSize.m}) {
      font-size: 4.5vw;
      line-height: 1.66;
    }
  }
`

export const SubTitle = styled.div`
  &[data-size="l"] {
    font-size: 2vw;
    line-height: 1.4;
    font-weight: 300;

    @media screen and (max-width: ${screenSize.s}) {
      font-size: 14px;
      line-height: 1.7;
    }

    @media screen and (max-width: ${screenSize.l}) {
      font-size: max(18px, min(2.8vw, 30px));
      line-height: 1.33;
    }

    @media screen and (max-width: ${screenSize.xl}) {
      font-size: 18px;
      line-height: 1.33;
    }
  }

  &[data-size="m"] {
    font-size: 1.25vw;
    line-height: 1;
    font-weight: 500;

    @media screen and (max-width: ${screenSize.xxl}) {
      font-size: 24px;
      line-height: 1;
    }

    @media screen and (max-width: ${screenSize.l}) {
      font-size: 1.75vw;
      line-height: 1.33;
    }

    @media screen and (max-width: ${screenSize.m}) {
      font-size: 3.75vw;
      line-height: 1.33;
    }

    @media screen and (max-width: ${screenSize.s}) {
      font-size: 5vw;
      line-height: 1.33;
    }
  }

  &[data-size="s"] {
    font-size: 1.5vw;
    line-height: 1.14;

    @media screen and (max-width: ${screenSize.m}) {
      font-size: 2.8vw;
      line-height: 1.33;
    }
  }
`

export const P = styled.p`
  font-size: 1.4vw;
  line-height: 1.33;

  @media screen and (max-width: ${screenSize.l}) {
    font-size: 1.4vw;
  }

  @media screen and (max-width: ${screenSize.m}) {
    font-size: 2.8vw;
  }

  @media screen and (max-width: ${screenSize.s}) {
    font-size: 4vw;
    line-height: 1.2;
  }

  &[data-size="m"] {
    font-size: 15px;
    line-height: 1.8;
    font-weight: 500;

    @media screen and (max-width: ${screenSize.m}) {
      font-size: 15px;
      line-height: 1.2;
    }
  }

  &[data-size="s"] {
    font-size: 15px;
    line-height: 1.4;
    text-transform: uppercase;

    @media screen and (max-width: ${screenSize.m}) {
      font-size: 18px;
      line-height: 2.66;
    }
  }
`

export const SubText = styled.div`
  font-size: 15px;
  line-height: 1.2;
  font-weight: 300;
`
