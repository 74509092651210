import React from "react"
import styled from "styled-components"
import "focus-visible"

import { screenSize } from "./variables"

const StyledLayout = styled.div`
  min-width: ${screenSize.xs};
  margin: 0 auto;
  font-family: "BrandonGrotesque", sans-serif;
  font-display: swap;

  html:not(.wf-brandongrotesque-n4-active) & {
    font-family: Helvetica, sans-serif;
    font-weight: 100 !important;
  }

  &[data-rtl="true"] {
    direction: ltr;
    font-family: "AvenirArabic", sans-serif;
    font-display: swap;
  }
`

export const Layout = ({ children, rtl }) => (
  <StyledLayout data-rtl={rtl}>{children}</StyledLayout>
)
